import { useState, useEffect, Fragment } from 'react';
import io from 'socket.io-client';
import './App.css';
import Bars from './components/Bars';

function App()
{
  const initialConnections = 
  [
    { connected: false, cpu: 0 },
    { connected: false, cpu: 0 },
    { connected: false, cpu: 0 }
  ];

  const [connections, setConnections] = useState(initialConnections);

  function effect()
  {
    const sockets = [];

    sockets[0] = io.connect('https://ouvm1.it-wiz.de:3002');
    sockets[1] = io.connect('https://ouvm2.it-wiz.de:3002');
    sockets[2] = io.connect('https://ouvm3.it-wiz.de:3002');

    function onMessage(index, data)
    {
      function updater(prevConnections)
      {
        const updatedConnections = [...prevConnections];
        updatedConnections[index].cpu = data;

        return updatedConnections;
      }

      setConnections(updater);
    }

    function onConnect(index)
    {
      function updater(prevConnections)
      {
        const updatedConnections = [...prevConnections];
        updatedConnections[index].connected = true;
        
        return updatedConnections;
      }

      setConnections(updater);

      console.log(`socket[${index}] connected...`);
    }

    function onDisconnect(index, reason)
    {
      function updater(prevConnections)
      {
        const updatedConnections = [...prevConnections];
        updatedConnections[index].connected = false;

        return updatedConnections;
      }

      setConnections(updater);

      console.log(`socket[${index}] disconnected; reason: ${reason}`);
    }

    function onConnectError(index, error)
    {
      function updater(prevConnections)
      {
        const updatedConnections = [...prevConnections];
        updatedConnections[index].connected = false;

        return updatedConnections;
      }

      setConnections(updater);
      
      // console.log(`onConnectError | host${index} triggered the connect_error event. Error message: ${error.message}`);
    }

    function attachHandlers(element, index)
    {
      element.on('server_cpu', (data) => { onMessage(index, data) });
      element.on('connect', () => { onConnect(index) });
      element.on('disconnect', (reason) => { onDisconnect(index, reason) });
      element.on('connect_error', (error) => { onConnectError(index, error) });
    }

    sockets.forEach(attachHandlers);

    function cleanup()
    {
      sockets.forEach(socket => socket.disconnect());
    }
    
    return cleanup;
  }

  useEffect(effect, []);

  function generateChart(connection, index)
  {
    const result =
      <Bars key={index} hostname={`ouvm${index + 1}`} connectionStatus={connection.connected} value={connection.cpu} />;

    return result;
  }

  const listCharts = connections.map(generateChart);

  const result =
    <Fragment>
      <div id='container'>
        <div id='title'>Host monitor: CPU</div>
        <div id='list-charts'>
          {listCharts}
        </div>
        <div id='attribution'><a rel='noreferrer' target='_blank' href='https://www.visualpharm.com/free-icons/disconnected-595b40b85ba036ed117dc074'>Icon from www.visualpharm.com</a></div>
      </div>
    </Fragment>;

  return result;
}

export default App;